import React from "react";
import {
  Heading,
  Image,
  SimpleGrid,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { SocialLinks } from "../components/SocialLinks";

const baseMediaUrl = "https://burh-media.s3.amazonaws.com/";

const aboutImages = [
  "resized_osita-2.jpg",
  "resized_osita-3.jpg",
  "resized_osita-4.jpg",
  "resized_osita-5.jpg",
  "vgh.png",
];

const AboutPage = () => {
  // @ts-ignore
  return (
    <>
      <SimpleGrid minChildWidth="200px" spacing="40px" p={"sm"}>
        <div>
          <Heading>About BURH:</Heading>
          <Text mt={6}>
            BURH is not just a brand; it's a symbol of unity among artists & a
            platform for giving back to those in need. We believe that
            communities are vital, & our brand exists to bring people together
            through art, collaboration, & fun, all while supporting meaningful
            causes.
          </Text>
          <Text mt={4}>
            Where art becomes a brushstroke of change in our beautiful world
          </Text>

          <Heading mt={12} size="s">
            Our Inspiration:
          </Heading>
          <Text mt={4}>
            Our name, BURH, draws inspiration from Anglo-Saxon fortifications &
            fortified communities known as "burhs" or "burgs." In the 9th
            century, these burhs were developed to defend against Viking raids.
            Similarly, we believe that communities make us stronger, help those
            in need, & allow us to have fun through art.
          </Text>

          <Heading mt={12} size="s">
            Charitable Initiatives:
          </Heading>
          <Text mt={4}>
            We are proud to share the success of our recent charitable
            endeavors. The February 2023 Art Auction raised an impressive
            $3,942.00 in proceeds. These funds will be directed towards cancer
            treatment and research, benefiting the VGH & UBC Hospital
            Foundation. We extend our heartfelt thanks to the artists who
            donated their artwork, as well as our partners at Osita – Live Stage
            & Cocktail Lounge in Vancouver for their support.
          </Text>

          <Heading mt={12} size="s">
            Our Mission:
          </Heading>
          <Text mt={4}>
            Our mission at BURH is simple: "sharing art." We believe that
            helping others and fundraising for local causes that touch our
            hearts are essential. Our commitment to giving back is a core part
            of who we are.
          </Text>

          <Heading mt={12} size="s">
            Supporting Eye Care Excellence:
          </Heading>
          <Text mt={4}>
            In addition to our cancer-related charitable efforts, we are
            dedicated to supporting excellence in healthcare. The charity Art
            Auction for VGH & UBC Hospital Foundation raised $2,500 to benefit
            the Eye Care Centre at VGH. We're grateful to the medical
            professionals, including Dr. Simon Holland, who have made a
            significant impact on people's lives.
          </Text>

          <Heading mt={12} size="s">
            Future Initiatives:
          </Heading>
          <Text mt={4}>
            At BURH, our journey in supporting our beautiful Health Authority is
            ongoing. We are excited to continue organizing charity events &
            initiatives that make a positive difference in our community.
          </Text>

          <Heading mt={12} size="s">
            Thank You:
          </Heading>
          <Text mt={4}>
            Finally, we want to express our sincere gratitude to all the
            artists, participants, & partners who have come together for these
            important causes. Your support and collaboration are what make
            giving back truly worthwhile.
          </Text>
        </div>
        <Wrap margin={"12px"} id="about-images" spacing="20px" justify="center">
          {aboutImages.map((image: string) => (
            <WrapItem key={`about-image-${image}`}>
              <Image
                src={`${baseMediaUrl}${image}`}
                alt={`${baseMediaUrl}${image}`}
              />
            </WrapItem>
          ))}
        </Wrap>
      </SimpleGrid>
      <SocialLinks />
    </>
  );
};

export default AboutPage;
