import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { Artwork, CartItem, Price, Product } from "../types";
import {
  loadFromLocalStorage,
  LocalStorageKeys,
  saveToLocalStorage,
} from "../localStorage";

interface ShoppingCartState {
  items: CartItem[];
}

const initialState: ShoppingCartState = {
  items: loadFromLocalStorage(LocalStorageKeys.CART) || [],
};

export const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    clearCart: (state) => {
      state.items = [];
      saveToLocalStorage(LocalStorageKeys.CART, state.items);
    },
    updateCache: (state) =>
      saveToLocalStorage(LocalStorageKeys.CART, state.items),
    addItem: (
      state,
      action: PayloadAction<{
        id: string;
        price: Price;
        product: Product;
        artwork: Artwork;
      }>,
    ) => {
      const { id, price, product, artwork } = action.payload;
      const cartArtwork = { ...artwork, image: artwork.images[0] };
      const currentItem = state.items.filter((e) => e?.id === id);
      if (!currentItem.length) {
        state.items.push({
          id,
          price,
          product,
          artwork: cartArtwork,
          quantity: 1,
        });
      } else {
        state.items = state.items.map((currentItem) => {
          if (currentItem.id === id) {
            currentItem.quantity += 1;
          }
          return currentItem;
        });
      }
      saveToLocalStorage(LocalStorageKeys.CART, state.items);
    },
    incrementItem: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.items = state.items.map((currentItem) => {
        if (currentItem.id === id) {
          currentItem.quantity += 1;
        }
        return currentItem;
      });
      saveToLocalStorage(LocalStorageKeys.CART, state.items);
    },
    decrementItem: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.items = state.items.map((currentItem) => {
        if (currentItem.id === id) {
          currentItem.quantity -= 1;
          currentItem.quantity = Math.max(0, currentItem.quantity);
        }
        return currentItem;
      });
      saveToLocalStorage(LocalStorageKeys.CART, state.items);
    },
  },
});

export const { addItem, clearCart, decrementItem, incrementItem, updateCache } =
  shoppingCartSlice.actions;

export const getCart = (state: RootState) => state.shoppingCart.items;
