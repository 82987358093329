import React from "react";
import {
  Heading,
  Image,
  SimpleGrid,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { SocialLinks } from "../components/SocialLinks";

const baseMediaUrl = "https://burh-media.s3.amazonaws.com/";

const images = [
  // "osita-1.jpg",
  "osita-2.jpg",
  "osita-3.jpg",
  "osita-4.jpg",
  "osita-5.jpg",
  // "vivace.jpg",
  // "cc-violin.jpg",
  "vgh.png",
  // "listel-hotel.png",
  // "cafe.jpg",
  "e-cordova.jpg",
  "e-cordova-1.jpg",
];

const EventPage = () => {
  return (
    <>
      <SimpleGrid minChildWidth="200px" spacing="40px" p={"sm"}>
        <div>
          <Heading>
            Welcome to BURH, where art becomes a brushstroke of change in our
            beautiful world.
          </Heading>
          <Heading mt={12} size="s">
            Art Auctions with Purpose
          </Heading>
          <Text mt={6}>
            Our signature events are a testament to our mission. Picture this: A
            room filled with captivating artwork, each stroke telling a unique
            story. As you explore, you're not just witnessing art; you're
            becoming part of it.
          </Text>
          <Text mt={2}>
            At our live art auctions, artists from our vibrant community
            generously contribute their work. These pieces are more than just
            art; they are opportunities for transformation. Proceeds from our
            auctions go to support charitable organizations, such as the VGH &
            UBC Hospital Foundation, BC Cancer, & Eye Care Centre at VGH.
          </Text>

          <Heading mt={12} size="s">
            Your Invitation to Create Change
          </Heading>

          <Text mt={6}>
            Our are about bringing people together. We invite you to join us in
            creating change, in celebrating creativity, and in giving back to
            the community.
          </Text>
          <Text mt={2}>
            Reach out to us. Let's discuss setting up an event that not only
            celebrates art but also makes a difference in the lives of others.
          </Text>
          <Text mt={6}>
            Email <a href="mailto:info@burh.ca">info@burh.ca</a> to initiate the
            conversation:
          </Text>
          <Text mt={2}>[Name]</Text>
          <Text>[Your Email Address]</Text>
          <Text>[Phone Number]</Text>
          <Text>[Event Insights]</Text>
          <Text mt={4}>
            Together, we can continue to support causes, enjoy artistic
            collaborations, & build a community where art thrives.
          </Text>
        </div>
        <Wrap mt={6} spacing={4}>
          {images.map((image) => (
            <WrapItem key={image}>
              <Image
                src={`${baseMediaUrl}${image}`}
                alt="event"
                style={{ width: "100%", height: "100%" }}
              />
            </WrapItem>
          ))}
        </Wrap>
      </SimpleGrid>
      <SocialLinks />
    </>
  );
};

export default EventPage;
