export enum LocalStorageKeys {
  CART = "burhShoppingCart",
  CHECKOUT_SESSION_ID = "stripeCheckoutSessionId",
}

export const saveToLocalStorage = (key: string, data: any) => {
  try {
    const serialisedState = JSON.stringify(data);
    localStorage.setItem(key, serialisedState);
  } catch (e) {
    console.warn(e);
  }
};

export const loadFromLocalStorage = (key: string) => {
  try {
    const serialisedState = localStorage.getItem(key);
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};
