import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Artist, Artwork, Category } from "../types";
import { buildUrl } from "../utils";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = process.env.REACT_APP_API_AUTH_TOKEN;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCategories: builder.query<Category[], string>({
      query: () => "/categories",
    }),
    getArtworks: builder.query<
      Artwork[],
      {
        categoryId?: string;
        offset?: string;
        limit?: string;
        artworkIds?: number[];
        tags?: string[] | null;
      }
    >({
      query: ({ categoryId, offset, limit, artworkIds, tags }) =>
        artworkIds && artworkIds.length > 0
          ? `/artworks?ids=${artworkIds.join(",")}`
          : tags == null
          ? "" // hack to prevent loading of all artworks
          : buildUrl("/artworks", {
              category_id: categoryId,
              offset,
              limit,
              tags: tags ? tags.join(",") : undefined,
            }),
    }),
    getArtworksCount: builder.query<number, string>({
      query: (categoryId: undefined | string) =>
        "".concat(
          "/artworks_count",
          categoryId ? `?category_id=${categoryId}` : "",
        ),
    }),
    getArtists: builder.query<Artist[], string>({
      query: () => `/artists`,
    }),
    getArtistByName: builder.query<Artist, string>({
      query: (artistName: string) => `/artists/${artistName}`,
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetArtworksQuery,
  useGetArtworksCountQuery,
  useGetArtistsQuery,
  useGetArtistByNameQuery,
} = apiSlice;
