import React from "react";
import {
  Heading,
  Image,
  SimpleGrid,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { SocialLinks } from "../components/SocialLinks";

const baseMediaUrl = "https://burh-media.s3.amazonaws.com/";

const images = [
  "resized_paint brush artist tools.JPG",
  "resized_Each square line triangle consists off colours materials, or colour material tools tools art artwork art gallery.JPG",
  "resized_Each square line triangle consists off colours materials, or colour material tools artists art art tools.JPG",
  "resized_Chaulk pastel tools materials .JPG",
  "resized_Artistsloft tools materials abstract artwork.JPG",
  "resized_Artists paint painting canvass brush artists tools.JPG",
  "resized_art tools pallete knives artists art studio.JPG",
  "resized_Art tools canvas wall decor.JPG",
];

const ToolsPage = () => {
  return (
    <>
      <SimpleGrid minChildWidth="200px" spacing="40px" p={"sm"}>
        <div>
          <Heading>Tips & Hints</Heading>
          <Text mt={6}>
            BURH Gallery is a space for showcasing art; for inclusivity,
            community engagement, and the transformative power of artistic
            expression. As artists embark on their exciting journeys within the
            gallery's walls, they gain top tips, tools, and insights to enhance
            their artistic prowess. The gallery actively guides the viewer
            through the artistic journey, manipulating liner movements, and
            defining the boundaries of creations to ensure that observers are
            not just spectators but active participants in the intricate
            tapestry of the artwork. BURH Gallery's approach ensures that the
            nuances of artistic expression are accessible to all, promoting a
            more nuanced and diverse artistic vision within the community. Using
            unconventional tools and pushing the boundaries of traditional
            painting, it sparks creativity and inspiration. By supporting BURH
            Gallery, we contribute to a shared vision of a community where art
            becomes a source of joy, self-expression,
          </Text>
          <Text mt={2}>
            At our live art auctions, artists from our vibrant community
            generously contribute their work. These pieces are more than just
            art; they are opportunities for transformation. Proceeds from our
            auctions go to support charitable organizations, such as the VGH &
            UBC Hospital Foundation, BC Cancer, & Eye Care Centre at VGH.
          </Text>

          <Heading mt={12} size="s">
            Embarking on the Artistic Journey
          </Heading>

          <Text mt={6}>
            Embarking on the exciting journey of painting opens up a world of
            possibilities, allowing your artistic abilities to flourish with the
            right guidance. Here, we offer essential tips, tools, and insights
            that not only facilitate your artistic expression but also inspire
            others to explore the mesmerizing realm of painting.
          </Text>

          <Heading mt={12} size="s">
            Guiding the Viewer Through Your Artistic Journey
          </Heading>

          <Text mt={6}>
            Control the viewer's gaze by manipulating liner movements,
            navigating through shapes, textures, colors, and enclosed spaces.
            Define the boundaries of your artistic pieces with thoughtful titles
            that serve as guiding beacons, leading observers through the
            intricate tapestry of your creation.
          </Text>

          <Heading mt={12} size="s">
            Elevating Your Artistic Arsenal
          </Heading>

          <Text mt={6}>
            Experiment with a diverse array of tools, such as paint brushes in
            various shapes, palette and painting knives, rollers, sponges, and
            unconventional materials. Create textures and dimensions that add
            depth to your work, allowing for a more nuanced expression of your
            artistic vision.
          </Text>

          <Heading mt={12} size="s">
            Playing with Acrylics and Pastels
          </Heading>

          <Text mt={6}>
            Explore the versatility of acrylics and pastels, considering their
            varying consistencies and effects. Utilize heavy body acrylics for
            thickness and soft body acrylics for consistency. Incorporate
            pastels to create dynamic contrasts or soften tones, adding depth to
            your masterpiece.
          </Text>

          <Heading mt={12} size="s">
            Preserving Your Artistic Legacy
          </Heading>

          <Text mt={6}>
            Meticulously apply varnish to completed paintings to protect your
            artwork from dust, UV rays, and yellowing. This crucial step ensures
            the longevity of your pieces, preserving their vivid beauty for
            years to come.
          </Text>

          <Heading mt={12} size="s">
            The Alchemy of Abstract Art
          </Heading>

          <Text mt={6}>
            Explore the alchemy of abstract art using pouring mediums, acrylic
            paint, and various surfaces. Embrace unconventional tools to add
            dynamic layers to your creations, pushing the boundaries of
            traditional painting. Embark on your painting journey armed with
            these fundamental insights, enhancing your artistic prowess and
            inspiring others to join you in the captivating world of painting.
            Unleash your creativity, leaving an indelible mark on the canvas of
            artistic expression
          </Text>
        </div>
        <Wrap mt={6} spacing={4}>
          {images.map((image) => (
            <WrapItem key={image}>
              <Image
                src={`${baseMediaUrl}${image}`}
                alt="event"
                style={{ width: "100%", height: "100%" }}
              />
            </WrapItem>
          ))}
        </Wrap>
      </SimpleGrid>
      <SocialLinks />
    </>
  );
};

export default ToolsPage;
