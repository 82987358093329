import React from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  IconButton,
  useDisclosure,
  Spacer,
  Center,
  DrawerFooter,
  useToast,
} from "@chakra-ui/react";
import { ShoppingCartIcon } from "../assets/shoppingCartIcon";
import { ShoppingCartOutlineIcon } from "../assets/shoppingCartOutlineIcon";
import { CartItem } from "../types";
import { clearCart, getCart } from "../redux/shoppingCartSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { CartItemView } from "./cartItem";
import { pink400 } from "../assets/tokens";
import { CheckoutFn } from "./checkout";
import { CloseIcon } from "@chakra-ui/icons";

export const ShoppingCart: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const items: CartItem[] = useAppSelector((state) => getCart(state));
  const isShoppingCartActive = items && items.length;

  console.log(items);
  return (
    <>
      <IconButton
        aria-label="Shopping cart"
        icon={
          isShoppingCartActive ? (
            <ShoppingCartIcon w={8} h={8} color={pink400} />
          ) : (
            <ShoppingCartOutlineIcon w={8} h={8} color={pink400} />
          )
        }
        variant={"outline"}
        onClick={onOpen}
        w={[12, 14]}
        h={[12, 14]}
      />
      <Drawer placement={"right"} onClose={onClose} isOpen={isOpen} size={"md"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Flex>
              <IconButton
                aria-label={"Close"}
                onClick={onClose}
                icon={<CloseIcon />}
              />
              <Spacer />
              <Center>
                <Text fontSize="xl">Cart</Text>
              </Center>
              <Spacer />
              <Button
                isDisabled={!items.filter((e) => e.quantity > 0).length}
                onClick={() => CheckoutFn()}
              >
                Checkout
              </Button>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            {items &&
              items.map((cartItem: CartItem) => (
                <CartItemView key={cartItem.id} item={cartItem} />
              ))}
            <Spacer />
            <Text fontSize="lg">
              Total: $
              {items
                .map((e) => e.price.unit_amount * e.quantity)
                .reduce((a, b) => a + b, 0) / 100}
            </Text>
          </DrawerBody>
          <DrawerFooter>
            <Flex w={"100%"}>
              <Spacer />
              <Button
                variant="outline"
                mr={3}
                isDisabled={!items.length}
                onClick={() => {
                  dispatch(clearCart());
                  toast({
                    title: "Cart cleared.",
                    description: `You have cleared your cart.`,
                    status: "info",
                    duration: 3000,
                    isClosable: true,
                  });
                  onClose();
                }}
              >
                Clear cart
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
