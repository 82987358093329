import React from "react";
import {
  Divider,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { SocialLinks } from "../components/SocialLinks";

const baseMediaUrl = "https://burh-media.s3.amazonaws.com/";

const images = [
  "resized_Art+tools+canvas+wall+decor.JPG",
  "resized_art+tools+pallete+knives+artists+art+studio.JPG",
  "resized_Artists+paint+painting+canvass+brush+artists+tools.JPG",
  "resized_Artistsloft+tools+materials+abstract+artwork.JPG",
  "resized_paint+brush+artist+tools.JPG",
];

const BlogPage = () => {
  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <GridItem colSpan={2}>
          <Heading>BLOG</Heading>
          <Text mt={6}>
            The BURH Gallery seeks artists who embody diverse artistic
            backgrounds and expressions. Each artist possesses a unique style
            and perspective, ranging from traditional painting and sketching to
            multimedia creations and numerical expressionism. They draw
            inspiration from various sources, including personal experiences,
            cultural influences, and the environment around them.
          </Text>
          <Text mt={6}>
            Where art becomes a brushstroke of change in our beautiful world.
          </Text>
          <Text mt={6}>
            Based on the overviews provided, BURH Gallery aims to recruit
            individuals who demonstrate versatility and proficiency in multiple
            mediums, particularly those who excel in visual storytelling and
            have experience across different artistic platforms. Candidates with
            a passion for exploring various artistic styles and techniques,
            coupled with a commitment to excellence and professionalism in their
            work, are highly sought after.
          </Text>
          <Text mt={6}>
            In summary, BURH Gallery looks for artists who are:
          </Text>
          <div>
            <ul style={{ margin: "1rem 2rem" }}>
              <li>
                Versatile and skilled in multiple mediums, such as painting,
                sketching, mixed-media art, or multimedia creations.
              </li>
              <li>
                Experienced in visual storytelling through mediums like
                children's book concepts, game concept art, or film
                storyboarding.
              </li>
              <li>
                Creative, original, and willing to push artistic boundaries.
              </li>
              <li>
                Strong communicators who can effectively convey their artistic
                vision and collaborate with others.
              </li>
              <li>
                Demonstrative of professionalism and dedication to their craft,
                as evidenced by past projects and achievements.
              </li>
            </ul>
          </div>
          <Text mt={6}>
            Prospective artists who embody the dynamic qualities demonstrated by
            the artists mentioned above are encouraged to apply to join the
            vibrant community at BURH Gallery.
          </Text>
          <Divider mt={6} />
          <Text mt={4}>
            "What are the essential supplies I need to start painting?"
          </Text>
          <Text mt={6}>
            Alright, let's dive into it. First off, you'll want to gather your
            paints - acrylic, oil, or watercolor, whichever you prefer. Grab
            brushes of various sizes & shapes, a palette for mixing those
            vibrant colors, & don't forget a canvas or painting surface. You'll
            also need palette knives for mixing & applying paint, & if you're
            using water-based paints, keep a container handy. An easel can be
            quite helpful too, though it's optional.
          </Text>
          <Text mt={6}>
            "How do I choose the right canvas for my paintings?"
          </Text>
          <Text mt={6}>
            Choosing the right canvas is key. Consider the size, texture, &
            material. For beginners, a pre-stretched canvas made of cotton or
            linen with a medium texture is your best bet. & remember, pick a
            size that suits your subject & the space you have in mind.
          </Text>
          <Text mt={6}>
            "What are some beginner-friendly painting techniques to try?"
          </Text>
          <Text mt={6}>
            Let's start simple. Begin with basics like layering, blending, dry
            brushing, & experimenting with different brush strokes. Try your
            hand at washes, glazing, & impasto techniques to add depth & texture
            to your work.
          </Text>
          <Text mt={6}>"How do I find inspiration for my artwork?"</Text>
          <Text mt={6}>
            Inspiration is everywhere! Look around you - nature, everyday life,
            galleries, books, & even online resources can spark creativity. Keep
            a sketchbook handy to capture those moments of inspiration whenever
            they strike.
          </Text>
          <Text mt={6}>
            "What are some tips for mixing colors effectively?"
          </Text>
          <Text mt={6}>
            Color mixing can be tricky, but fear not! Start with a limited color
            palette & learn to mix primary colors to create secondary & tertiary
            ones. Dive into color theory to understand how different colors
            interact & complement each other.
          </Text>
          <Text mt={6}>"How can I improve my brushwork & techniques?"</Text>
          <Text mt={6}>
            Practice, practice, practice! Experiment with different brushes &
            techniques regularly. Study the works of master painters & try to
            replicate their brushwork to learn new techniques & refine your
            skills.
          </Text>
          <Text mt={6}>
            "What are some common mistakes to avoid as a beginner painter?"
          </Text>
          <Text mt={6}>
            Ah, the pitfalls of painting. Avoid overworking your paintings,
            neglecting composition, using too much paint, & being overly
            critical of your work. Embrace mistakes as learning opportunities &
            keep experimenting!
          </Text>
          <Text mt={6}>"How do I know when a painting is finished?"</Text>
          <Text mt={6}>
            Knowing when to stop can be tough. Trust your instincts & step back
            to assess your work from a distance. Look for balance, harmony, &
            unity in your composition. Remember, sometimes it's better to leave
            a painting unfinished than to overwork it.
          </Text>
          <Text mt={6}>"What's the best way to overcome artist's block?"</Text>
          <Text mt={6}>
            Artist's block is no joke, but there are ways to push through. Take
            a break, explore other creative outlets, experiment with new
            techniques, & seek inspiration from different sources. Don't be
            afraid to make mistakes - sometimes stepping away from your work can
            provide a fresh perspective.
          </Text>
          <Text mt={6}>"How do I price my artwork as a beginner?"</Text>
          <Text mt={6}>
            Pricing your artwork can be daunting, but it's doable. Do your
            research on similar artworks in the market, considering factors like
            size, medium, complexity, & time invested. Be open to negotiation &
            start with modest prices, gradually increasing them as your skill &
            reputation grow.
          </Text>
        </GridItem>
        <GridItem>
          <Wrap mt={6} spacing={4}>
            {images.map((image) => (
              <WrapItem key={image}>
                <Image
                  src={`${baseMediaUrl}${image}`}
                  alt="event"
                  style={{ width: "100%", height: "100%" }}
                />
              </WrapItem>
            ))}
          </Wrap>
        </GridItem>
      </Grid>
      <SocialLinks />
    </>
  );
};

export default BlogPage;
