import { green500, pink500 } from "../../assets/tokens";
import { Button } from "@chakra-ui/react";
import React from "react";

type Props = {
  title: string;
  isDisabled?: boolean;
  onClick: () => void;
};

const SecondaryButton = ({ title, isDisabled = false, onClick }: Props) => {
  return (
    <Button
      type="submit"
      variant="solid"
      color={pink500}
      isDisabled={isDisabled}
      onClick={() => onClick()}
    >
      {title}
    </Button>
  );
};

export default SecondaryButton;
