import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Heading,
  ModalFooter,
  Spacer,
} from "@chakra-ui/react";
import ArtistProfile from "./artistProfile";
import { Artist } from "../types";
import SecondaryButton from "./buttons/secondaryButton";
import PrimaryButton from "./buttons/primaryButton";
import { useNavigate } from "react-router-dom";
import { formatNameUrl } from "../utils";

type Props = {
  artist: Artist;
};

const ArtistModal = ({ artist }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <>
      <Heading size="s" onClick={onOpen} style={{ cursor: "pointer" }}>
        <u>{artist.name}</u>
      </Heading>

      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody display={"flex"} justifyContent={"center"}>
            <ArtistProfile
              name={artist.name}
              title={artist.title}
              overview={artist.overview}
              about={artist.about || ""}
            />
          </ModalBody>
          <ModalFooter display={"flex"}>
            <PrimaryButton
              title={"See more"}
              onClick={() => navigate(`/artists/${formatNameUrl(artist.name)}`)}
            />
            <Spacer />
            <SecondaryButton title={"Close"} onClick={onClose} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ArtistModal;
