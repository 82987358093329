import React from "react";
import { SimpleGrid, Spinner, Wrap, WrapItem } from "@chakra-ui/react";
import { useGetArtistByNameQuery } from "../redux/apiSlice";
import { useLoaderData } from "react-router-dom";
import ArtistProfile from "../components/artistProfile";
import ArtworkCard from "../components/artworkCard";
import { Artwork } from "../types";
import { capitalise } from "../utils";
import { SocialLinks } from "../components/SocialLinks";

// @ts-ignore
export async function loader({ params }) {
  // parse artist name from lowercase, dash-separated url by removing dashes and capitalizing
  const artistName = params?.artistName
    .split("-")
    .map((word: string) => capitalise(word))
    .join(" ");
  return { artistName };
}

const ArtistPage = () => {
  // @ts-ignore
  const { artistName } = useLoaderData();
  const {
    data: artist,
    error: errorLoadingArtist,
    isLoading: isLoadingArtist,
  } = useGetArtistByNameQuery(artistName || "");

  const getArtworks = (artworks: Artwork[]) => {
    return artworks.slice(0, 3);
  };

  return (
    <>
      {isLoadingArtist ? (
        <Spinner />
      ) : (
        artist && (
          <SimpleGrid minChildWidth="200px" spacing="40px" p={"sm"}>
            <ArtistProfile
              name={artist.name}
              title={artist.title}
              overview={artist.overview}
              about={artist.about || ""}
            />
            <Wrap mt={6} spacing={4}>
              {artist?.artworks &&
                getArtworks(artist.artworks)?.map((artwork: Artwork) => (
                  <WrapItem key={artwork.id}>
                    <ArtworkCard artwork={artwork}></ArtworkCard>
                  </WrapItem>
                ))}
            </Wrap>
          </SimpleGrid>
        )
      )}
      <SocialLinks />
    </>
  );
};

export default ArtistPage;
