import React from "react";
import { Grid, GridItem, Text, Heading } from "@chakra-ui/react";
import { SocialLinks } from "../components/SocialLinks";

const CareersPage = () => {
  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <GridItem colSpan={2}>
          <Text mt={2}>
            Company: BURH Gallery  www.BURH.ca   @BURH.gallery FB & IG
          </Text>
          <Text mt={2}>Location: Remote</Text>
          <Text mt={2}>100% Commission Based</Text>
          <Heading mt={12} size="s">
            About BURH:
          </Heading>
          <Text mt={6}>
            BURH is all about bringing artists together and giving back to those
            in need. We believe in the strength of communities, art, and fun,
            while supporting important causes. Our name, BURH, comes from
            ancient fortifications, showing how communities make us stronger and
            allow us to enjoy art. Through events like art auctions, we raise
            money for causes we care about, like supporting BC’s Healthcare.
          </Text>
          <Heading mt={12} size="s">
            Job Description:
          </Heading>
          <Text mt={6}>
            Job Description: BURH Gallery is looking for an enthusiastic Art
            Sales and Marketing Specialist to join us. If you have a passion for
            art and are skilled in social media and email marketing, we want
            you! This remote role gives you the chance to help with charity
            events and connect with fellow art lovers.
          </Text>
          <div>
            Responsibilities:
            <ul style={{ margin: "1rem 2rem" }}>
              <li>
                Manage social media (Facebook, Instagram) for sales and
                engagement.
              </li>
              <li>Execute email marketing campaigns using Mailchimp.</li>
              <li>Implement sales strategies to meet revenue targets.</li>
              <li>Analyze campaign performance with Google Analytics.</li>
              <li>Coordinate charity events and auctions.</li>
              <li>Network with art enthusiasts and professionals.</li>
            </ul>
            Requirements:
            <ul style={{ margin: "1rem 2rem" }}>
              <li>Recent art degree or equivalent experience.</li>
              <li>Proficiency in social media & email marketing.</li>
              <li>Understanding of sales techniques and KPI analysis.</li>
              <li>
                Familiarity with digital marketing goals & google analytics.
              </li>
              <li>Highly organized & proactive.</li>
              <li>Passion for art & local events.</li>
            </ul>
            Benefits:
            <ul style={{ margin: "1rem 2rem" }}>
              <li>Four weeks of holiday per year.</li>
              <li>Remote work flexibility.</li>
              <li>Exposure to charity & networking events.</li>
            </ul>
            Please send your resume to info@BURH.ca to apply.
          </div>
        </GridItem>
      </Grid>
      <SocialLinks />
    </>
  );
};

export default CareersPage;
