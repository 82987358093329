import { green500 } from "../../assets/tokens";
import { Button } from "@chakra-ui/react";
import React from "react";

type Props = {
  title: string;
  isDisabled?: boolean;
  onClick: () => void;
};

const PrimaryButton = ({ title, isDisabled = false, onClick }: Props) => {
  return (
    <Button
      bgColor={green500}
      color="white"
      isDisabled={isDisabled}
      onClick={() => onClick()}
    >
      {title}
    </Button>
  );
};

export default PrimaryButton;
