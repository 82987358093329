import React from "react";
import { Grid, GridItem, Text, Heading } from "@chakra-ui/react";
import { SocialLinks } from "../components/SocialLinks";

const FAQPage = () => {
  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <GridItem colSpan={2}>
          <Heading mt={2}>
            BURH gallery FAQ - Frequently Asked Questions
          </Heading>
          <Text mt={4} fontWeight={600}>
            Question 1: What services does your canvas print company offer?
          </Text>
          <Text mt={2}>
            Answer: BURH Gallery collaborates with TK Graphics & Kimprints to
            offer exceptional canvas productions & framing services. We provide
            custom sizes, framing options, and personalized craftsmanship to
            suit your unique preferences.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 2: How can I request a quote for my canvas printing
            project?
          </Text>
          <Text mt={2}>
            Answer: For custom sizes, frames, or finishing, please reach out to
            us at
            <a href="mailto:info@burh.ca">info@burh.ca</a>.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 3: What types of canvas materials and printing processes do
            you use?
          </Text>
          <Text mt={2}>
            Answer: We use top-quality canvas fabric stretched over handcrafted
            spruce wood stretcher frames. Our printing process involves 4cp
            digital output onto the canvas.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 4: What file formats do you accept for artwork submission
            for canvas prints?
          </Text>
          <Text mt={2}>
            Answer: We accept various file formats for artwork submission,
            ensuring flexibility for our clients' needs.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 5: What is the turnaround time for canvas printing orders?
          </Text>
          <Text mt={2}>
            Answer: Our turnaround time depends on the specifics of each order.
            Please contact us for more information regarding turnaround times.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 6: Can I see samples of your previous canvas print work?
          </Text>
          <Text mt={2}>
            Answer: Yes, we can provide samples of our previous canvas print
            work upon request.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 7: Do you offer design services for canvas prints, or do I
            need to provide my own artwork?
          </Text>
          <Text mt={2}>
            Answer: We can accommodate both options. We offer design services,
            or you can provide your own artwork for printing.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 8: What is your policy on proofing and revisions for canvas
            prints?
          </Text>
          <Text mt={2}>
            Answer: Our policy on proofing and revisions ensures customer
            satisfaction. We work closely with clients to finalize designs
            before printing.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 9: What payment methods do you accept for canvas print
            orders?
          </Text>
          <Text mt={2}>
            Answer: We accept various payment methods for canvas print orders,
            providing convenience for our clients.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 10: Do you offer shipping or delivery services for canvas
            prints?
          </Text>
          <Text mt={2}>
            Answer: Yes, we offer shipping or delivery services for canvas
            prints to ensure safe and timely delivery to our clients.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 11: What is the typical delivery time for canvas prints?
          </Text>
          <Text mt={2}>
            Answer: 1-2 weeks across Canada & USA, depending on the specifics of
            each order. Up to 4-6 weeks for international orders. Please contact
            us for more information regarding delivery times.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 12: What is your policy on returns or refunds for canvas
            prints?
          </Text>
          <Text mt={2}>
            Answer: Our policy on returns or refunds is designed to address any
            issues with canvas prints and ensure customer satisfaction.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 13: Can I order custom sizes for my canvas prints?
          </Text>
          <Text mt={2}>
            Answer: Yes, we offer custom sizes for canvas prints to meet the
            specific needs of our clients.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 14: Do you offer any eco-friendly canvas printing options?
          </Text>
          <Text mt={2}>
            Answer: We are committed to sustainability and offer eco-friendly
            canvas printing options upon request.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 15: Can you assist with large-scale canvas printing
            projects or bulk orders?
          </Text>
          <Text mt={2}>
            Answer: Yes, we can assist with large-scale canvas printing projects
            and bulk orders to accommodate various client needs.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 16: Are there any discounts available for bulk or repeat
            canvas print orders?
          </Text>
          <Text mt={2}>
            Answer: We offer discounts for bulk or repeat canvas print orders as
            part of our commitment to customer satisfaction.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 17: How do I track the status of my canvas print order?
          </Text>
          <Text mt={2}>
            Answer: You can track the status of your canvas print order by
            contacting us directly, and we will provide you with the necessary
            information.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 18: What should I do if I encounter any issues with my
            canvas print order?
          </Text>
          <Text mt={2}>
            Answer: If you encounter any issues with your canvas print order,
            please contact us immediately, and we will work to resolve the issue
            promptly.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 19: Do you have a minimum order quantity for canvas prints?
          </Text>
          <Text mt={2}>
            Answer: We do not have a minimum order quantity for canvas prints,
            allowing flexibility for our clients' needs.
          </Text>
          <Text mt={4} fontWeight={600}>
            Question 20: Can I order personalized or customized canvas prints?
          </Text>
          <Text mt={2}>
            Answer: Yes, you can order personalized or customized canvas prints
            to make your artwork truly unique.
          </Text>
          <Text mt={4} fontWeight={600} marginBottom={"4rem"}>
            If you’d like more answers, or you have more questions, please email
            us at <a href={"info@burh.ca"}>info@burh.ca</a>.
          </Text>
        </GridItem>
      </Grid>
      <SocialLinks />
    </>
  );
};

export default FAQPage;
