import React from "react";
import {
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Heading,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Artist, Artwork, Product } from "../types";
import { addItem, updateCache } from "../redux/shoppingCartSlice";
import { useAppDispatch } from "../redux/hooks";
import { CheckoutFn } from "./checkout";
import ImageModal from "./imageModal";
import ArtistModal from "./artistModal";
import PrimaryButton from "./buttons/primaryButton";
import SecondaryButton from "./buttons/secondaryButton";

type Props = {
  artwork: Artwork;
  artist?: Artist;
};

const ArtworkCard: React.FC<Props> = ({ artwork, artist }) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const formatUnitAmount = (unitAmount: number) => {
    return `$${(unitAmount / 100).toFixed(2)}`;
  };
  const productTitle = (product: Product) => {
    return `${product?.description} - ${formatUnitAmount(
      product?.price?.unit_amount,
    )}`;
  };

  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(
    null,
  );
  const [selectError, setSelectError] = React.useState<boolean>(false);

  const handleProductChange = (e: any) => {
    const selected = artwork.products?.find(
      (product) => product?.stripe_id === e.target.value,
    );
    setSelectedProduct(selected || null);
  };

  const handleAddItem = () => {
    if (selectedProduct) {
      try {
        dispatch(
          addItem({
            id: selectedProduct.price.stripe_id,
            price: selectedProduct.price,
            product: selectedProduct,
            artwork,
          }),
        );
        toast({
          title: "Item added to cart!",
          description: `We've added ${artwork.title} (${selectedProduct.description}) to your cart for you.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (err) {
        toast({
          title: "Oh no, something went wrong!",
          description: `We couldn't add that item to your cart for you.
                      Please try again or contact info@burh.ca if issue persists.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
    }
    return true;
  };

  const handleBuyNow = () => {
    console.log("clicked buy now");
    handleAddItem();
    dispatch(updateCache());
    CheckoutFn();
  };

  return (
    <Card maxW="sm">
      <CardBody>
        <ImageModal image={artwork.images[0]} />
        <Stack mt="6" spacing="3">
          <Heading size="md">{artwork.title}</Heading>
          {artist && <ArtistModal artist={artist} />}
          <Text>{artwork.description}</Text>
        </Stack>
      </CardBody>
      <Divider />
      <CardFooter>
        <Select
          placeholder="SELECT SIZE"
          onChange={(e) => handleProductChange(e)}
          textColor={selectError ? "red.500" : "green.600"}
          textStyle={"bold"}
          borderColor={selectError ? "red.500" : "green.600"}
          focusBorderColor={selectError ? "red.500" : "green.600"}
        >
          {artwork &&
            artwork.products
              ?.filter((product) => product.is_active)
              .map((product) => (
                <option key={product?.id} value={product?.stripe_id}>
                  {productTitle(product)}
                </option>
              ))}
        </Select>
        <ButtonGroup
          spacing="0"
          onMouseEnter={() => (!selectedProduct ? setSelectError(true) : null)}
          onMouseLeave={() => setSelectError(false)}
        >
          <SecondaryButton
            title={"Buy now"}
            onClick={() => handleBuyNow()}
            isDisabled={!selectedProduct}
          />
          <PrimaryButton
            title={"Add to cart"}
            onClick={() => handleAddItem()}
            isDisabled={!selectedProduct}
          />
        </ButtonGroup>
      </CardFooter>
    </Card>
  );
};

export default ArtworkCard;
