import React from "react";
import { Center, Heading, Spinner, Text } from "@chakra-ui/react";
import { getCheckout } from "../network/endpoints";
import { useParams } from "react-router-dom";

const DonateSuccess = () => {
  let { sessionId } = useParams();
  const [customerName, setCustomerName] = React.useState<string>("Customer");
  const [error, setError] = React.useState<boolean>(false);
  const [fetching, setFetching] = React.useState<boolean>(true);

  if (sessionId) {
    getCheckout(sessionId)
      .then((res: any) => {
        if (res && res.data && res.data.order) {
          setCustomerName(res.data.customer.name);
        }
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      })
      .finally(() => setFetching(false));
  }

  return (
    <>
      {fetching ? (
        <Center marginTop={"200px"}>
          <Spinner size={"xl"} color={"green.500"} />
        </Center>
      ) : (
        <>
          {error ? (
            <>
              <Heading>Something went wrong!</Heading>
              <Text>
                We processed your EasyPay but can't retrieve the details! Please
                email <a href="mailto:info@burh.ca">info@burh.ca</a> if you
                don't receive an EasyPay confirmation email.
              </Text>
            </>
          ) : (
            <>
              <Heading>Thank you, {customerName}!</Heading>
              <Text>Your EasyPay has been received.</Text>
              <Text>
                You will receive an email with your EasyPay confirmation
                shortly. If you don't receive an email please contact support at{" "}
                <a href="mailto:info@burh.ca">info@burh.ca</a>. Please include
                your full name and postal code in the request.
              </Text>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DonateSuccess;
