import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

type Props = {
  children?: React.ReactNode;
  name: string;
  title: string;
  overview: string;
  about: string;
};
const ArtistProfile: React.FC<Props> = ({
  children,
  name,
  title,
  overview,
  about,
}) => {
  return (
    <Box p={5}>
      <Heading>{name}</Heading>
      <Heading size="s">Title: {title}</Heading>
      <Text mt={4}>Overview: {overview}</Text>

      <Text mt={4}>About: {about}</Text>
    </Box>
  );
};

export default ArtistProfile;
