import React from "react";
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";

type Props = {
  image: string;
};

const ImageModal = ({ image }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Image
        src={image}
        alt={image}
        borderRadius="sm"
        maxH="sm"
        loading="eager"
        // onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody display={"flex"} justifyContent={"center"}>
            <Image
              src={image}
              alt={image}
              borderRadius="sm"
              maxH="2xl"
              loading="eager"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImageModal;
