import React from "react";
import { Center, Divider, Heading, Spinner, Text } from "@chakra-ui/react";
import { LocalStorageKeys, saveToLocalStorage } from "../localStorage";
import { getCheckout } from "../network/endpoints";
import { clearCart } from "../redux/shoppingCartSlice";
import { useAppDispatch } from "../redux/hooks";
import { useParams } from "react-router-dom";

const CheckoutSuccess = () => {
  let { sessionId } = useParams();
  const dispatch = useAppDispatch();
  const [orderNo, setOrderNo] = React.useState<number>(1);
  const [customerName, setCustomerName] = React.useState<string>("Customer");
  const [error, setError] = React.useState<boolean>(false);
  const [fetching, setFetching] = React.useState<boolean>(true);

  if (sessionId) {
    getCheckout(sessionId)
      .then((res: any) => {
        if (res && res.data && res.data.order) {
          saveToLocalStorage(LocalStorageKeys.CHECKOUT_SESSION_ID, "");
          saveToLocalStorage(LocalStorageKeys.CART, "");
          dispatch(clearCart());
          setOrderNo(res.data.order);
          setCustomerName(res.data.customer.name);
        }
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      })
      .finally(() => setFetching(false));
  }

  return (
    <>
      {fetching ? (
        <Center marginTop={"200px"}>
          <Spinner size={"xl"} color={"green.500"} />
        </Center>
      ) : (
        <>
          {error ? (
            <>
              <Heading m={6}>Something went wrong!</Heading>
              <Text mt={6}>
                We processed your order but can't retrieve the details! Please
                email <a href="mailto:info@burh.ca">info@burh.ca</a> if you
                don't receive an order confirmation email.
              </Text>
            </>
          ) : (
            <>
              <Heading mt={6}>Thank you, {customerName}!</Heading>
              <Text mt={6}>Order confirmation no.: {orderNo}</Text>
              <Text mt={6}>
                You will receive an email with your order details shortly. If
                you don't receive an email please contact support at{" "}
                <a href="mailto:info@burh.ca">info@burh.ca</a>. Please include
                your order number in your email.
              </Text>
              <Divider m={6} />
              <Text mt={4} fontWeight={600}>
                BURH Gallery collaborates with TK Graphics & Kimprints to offer
                exceptional canvas productions & framing services:
              </Text>
              <Heading as={"h3"} mt={4} size="md">
                Borders & Framing: Frame It Your Way
              </Heading>
              <Text mt={4}>
                Elevate your artwork with top-quality canvas fabric, manually
                stretched over handcrafted spruce wood stretcher frames.
                Customize your canvas by choosing from 0.75 inch or 1.5 inch
                thickness & various edge designs. Explore premium frame options
                in Oak, Rosewood, Black, or White to add a personalized touch.
              </Text>
              <Heading as={"h3"} mt={4} size="md">
                TK Graphics: A Division of New World Screen Printing Ltd.
              </Heading>
              <Text mt={4}>
                Established in 1993, TK Graphics is a family-owned business
                known for its superior graphics services. With state-of-the-art
                equipment & expertise, we ensure top-notch quality in every
                product, from printing to finishing touches.
              </Text>
              <Heading as={"h3"} mt={4} size="md">
                Kimprints: Vancouver's Premier Framing Since 1984
              </Heading>
              <Text mt={4}>
                Locally owned & operated, Kimprints offers high-quality custom
                framing services & unique gifts. With over three decades of
                experience, we provide personalized craftsmanship & exceptional
                service to our clients.
              </Text>
              <Text mt={4} fontWeight={600}>
                Tailored to Your Vision
              </Text>
              <Text mt={4}>
                At BURH Gallery, we offer custom sizes & framing options to suit
                your unique preferences. Contact us today to discuss your
                project requirements & let us help you create a masterpiece that
                reflects your individual style.
              </Text>
              <Text mt={4}>
                For custom sizes, frames or finishing please do reach out{" "}
                <a href="mailto:info@burh.ca">info@burh.ca</a>
              </Text>
              <Text mt={4}>Stock: Canvas with 1.25" wooden frame</Text>
              <Text>
                Image: 4cp digital output, stretched onto 1.25" wooden stretcher
                frame, hanging hardware at back (please confirm if you would
                like hanging clip or hanging wire)
              </Text>
              <Text>
                <a href="https://kimprints.com/">https://kimprints.com/</a>
              </Text>
              <Text>
                <a href="https://tkgraphics.ca/">https://tkgraphics.ca/</a>
              </Text>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CheckoutSuccess;
