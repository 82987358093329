import React, { useEffect } from "react";
import { Center, Spinner, Wrap, WrapItem } from "@chakra-ui/react";
import { Artwork } from "../types";
import {
  useGetArtistsQuery,
  useGetArtworksQuery,
  useGetArtworksCountQuery,
  useGetCategoriesQuery,
} from "../redux/apiSlice";
import { useLoaderData } from "react-router-dom";
import ArtworkCard from "../components/artworkCard";
import InfiniteScroll from "../components/infiniteScroll";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  clearArtworks,
  getArtworks,
  getIsFetchingArtworks,
  updateArtworks,
} from "../redux/artworksSlice";
import { IMAGE_COUNT } from "../settings";

// @ts-ignore
export async function loader({ params }) {
  const category = params?.categoryName;
  return { category };
}

const ArtworkList = () => {
  const dispatch = useAppDispatch();

  // @ts-ignore
  const { category } = useLoaderData();
  const [categoryId, setCategoryId] = React.useState<undefined | string>(
    undefined,
  );

  const artworks: Artwork[] = useAppSelector((state) => getArtworks(state));
  const isFetchingArtworks: boolean = useAppSelector((state) =>
    getIsFetchingArtworks(state),
  );
  const [offset, setOffset] = React.useState<string>("0");

  const {
    data: categories = [],
    error: errorLoadingCategories,
    isLoading: isLoadingCategories,
  } = useGetCategoriesQuery("");

  useEffect(() => {
    if (categories && categories.length > 0 && category) {
      const categoryUpdate = categories.find(
        (cat: any) => cat.name.toLowerCase() === category,
      )?.id;
      setCategoryId(categoryUpdate?.toString() || "1");
      dispatch(clearArtworks());
      setOffset("0");
    }
  }, [category, categories]);

  const { data: artworksCount = 0 } = useGetArtworksCountQuery(
    categoryId?.toString() || "",
  );

  const {
    data: fetchedArtworks = [],
    error,
    isLoading,
  } = useGetArtworksQuery({
    categoryId: categoryId?.toString() || "",
    offset: offset.toString(),
    limit: IMAGE_COUNT.toString(),
  });

  const { data: artists = [] } = useGetArtistsQuery("");

  useEffect(() => {
    if (fetchedArtworks && fetchedArtworks.length > 0) {
      dispatch(updateArtworks(fetchedArtworks));
    }
  }, [offset, fetchedArtworks]);

  const getArtist = (artistId: number) => {
    return artists.find((artist: any) => artist.id === artistId);
  };

  return (
    <InfiniteScroll
      items={artworks}
      maxItems={artworksCount}
      currentOffset={parseInt(offset)}
      isFetching={isFetchingArtworks}
      setOffset={setOffset}
    >
      {isLoading || isLoadingCategories ? (
        <Center margin={"20px"}>
          <Spinner size={"xl"} color={"green.500"} />
        </Center>
      ) : (
        <>
          <Wrap id="collection-list" spacing="60px" justify="center">
            {artworks &&
              artworks
                .filter((artwork: Artwork) => artwork.artist_id !== 2)
                .map((artwork: Artwork) => (
                  <WrapItem key={artwork.id}>
                    <ArtworkCard
                      artwork={artwork}
                      artist={getArtist(artwork.artist_id)}
                    ></ArtworkCard>
                  </WrapItem>
                ))}
          </Wrap>
          {isFetchingArtworks && (
            <Center margin={"20px"}>
              <Spinner size={"xl"} color={"green.500"} />
            </Center>
          )}
        </>
      )}
    </InfiniteScroll>
  );
};

export default ArtworkList;
