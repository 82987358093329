import React from "react";
import { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { Category } from "../types";
import { Link } from "react-router-dom";
import { Skeleton } from "@chakra-ui/react";

type Props = {
  children?: React.ReactNode;
  categories: Category[];
  isLoading: boolean;
};

type MenuState = {
  children?: React.ReactNode;
  onStateChange: (state: any) => any;
  isOpen: boolean;
};

const BurgerMenu: React.FC<Props> = ({ children, categories, isLoading }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const checkOpen = () => {
    return openDrawer;
  };

  const isMenuOpen = (state: MenuState) => {
    setOpenDrawer(state.isOpen);
    return state.isOpen;
  };

  return (
    // @ts-ignore
    <Menu onStateChange={isMenuOpen} isOpen={checkOpen()}>
      {isLoading && (
        <div>
          <Skeleton marginTop={"10px"} height="40px" width="100%" />
          <Skeleton marginTop={"10px"} height="40px" width="100%" />
          <Skeleton marginTop={"10px"} height="40px" width="100%" />
          <Skeleton marginTop={"10px"} height="40px" width="100%" />
        </div>
      )}
      {!isLoading && (
        <ul>
          <li key={"/"} className="menu-list-element">
            <Link to={"/"} onClick={() => setOpenDrawer(false)}>
              Canvas Prints
            </Link>
          </li>
          <li key={"/about"} className="menu-list-element">
            <Link to={"/about"} onClick={() => setOpenDrawer(false)}>
              About
            </Link>
          </li>
          <li key={"/blog"} className="menu-list-element">
            <Link to={"/blog"} onClick={() => setOpenDrawer(false)}>
              Blog
            </Link>
          </li>
          <li key={"/events"} className="menu-list-element">
            <Link to={"/events"} onClick={() => setOpenDrawer(false)}>
              Events
            </Link>
          </li>
          <li key={"/tips&hints"} className="menu-list-element">
            <Link to={"/tips&hints"} onClick={() => setOpenDrawer(false)}>
              Tips & Hints
            </Link>
          </li>
          <li key={"/easypay"} className="menu-list-element">
            <Link to={"/easypay"} onClick={() => setOpenDrawer(false)}>
              EasyPay
            </Link>
          </li>
          <li key={"/careers"} className="menu-list-element">
            <Link to={"/careers"} onClick={() => setOpenDrawer(false)}>
              Careers
            </Link>
          </li>
          <li key={"/faq"} className="menu-list-element">
            <Link to={"/faq"} onClick={() => setOpenDrawer(false)}>
              FAQ
            </Link>
          </li>
        </ul>
      )}
      {!isLoading && categories.length === 0 && (
        <p style={{ margin: "10px" }}>
          <i>Error fetching artwork categories!</i>
        </p>
      )}
    </Menu>
  );
};

export default BurgerMenu;
