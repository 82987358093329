import { CartItem } from "../types";
import { checkout } from "../network/endpoints";
import { loadFromLocalStorage, LocalStorageKeys } from "../localStorage";

export const CheckoutFn = async () => {
  const items: CartItem[] = loadFromLocalStorage(LocalStorageKeys.CART);
  let url: null | string = null;
  if (items && items.length) {
    const payload = items
      .filter((item: CartItem) => item.quantity > 0)
      .map((item: CartItem) => ({
        price: item.price.stripe_id,
        quantity: item.quantity,
      }));
    await checkout(payload)
      .then((res: { data: any }) => {
        const checkoutSession = res.data.checkout_session;
        url = checkoutSession.url;
      })
      .catch((err: any) => {
        console.log(err);
      });
    if (url) {
      window.location.href = url;
    }
  }
};
