import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./routes/error-page";
import CategoryList from "./routes/categoryList";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ArtworkList, { loader as artworkLoader } from "./routes/artworkList";
import ArtistPage, { loader as artistLoader } from "./routes/artistPage";
import CheckoutSuccess from "./routes/checkoutSuccess";
import AboutPage from "./routes/aboutPage";
import EventPage from "./routes/eventPage";
import DonatePage from "./routes/donationPage";
import DonateSuccess from "./routes/donateSuccess";
import ToolsPage from "./routes/toolsPage";
import BlogPage from "./routes/blogPage";
import CareersPage from "./routes/careersPage";
import FAQPage from "./routes/FAQPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <CategoryList />,
      },
      {
        path: "/:categoryName",
        element: <ArtworkList />,
        loader: artworkLoader,
      },
      {
        path: "blog",
        element: <BlogPage />,
      },
      {
        path: "artists/:artistName",
        element: <ArtistPage />,
        loader: artistLoader,
      },
      {
        path: "checkout-success/:sessionId",
        element: <CheckoutSuccess />,
      },
      {
        path: "donate-success/:sessionId",
        element: <DonateSuccess />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "events",
        element: <EventPage />,
      },
      {
        path: "tips&hints",
        element: <ToolsPage />,
      },
      {
        path: "easypay",
        element: <DonatePage />,
      },
      {
        path: "careers",
        element: <CareersPage />,
      },
      {
        path: "faq",
        element: <FAQPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <Provider store={store}>
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
