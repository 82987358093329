import React from "react";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { HStack } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";

export const SocialLinks = () => {
  return (
    <HStack justifyContent={"center"}>
      <a href="https://www.instagram.com/burh.gallery/">
        <Icon as={FaInstagram} w={20} h={20} />
      </a>
      <a href="https://www.facebook.com/burh.gallery">
        <Icon as={FaFacebook} w={20} h={20} />
      </a>
    </HStack>
  );
};
