import React from "react";
import { Link, Outlet } from "react-router-dom";
import logo from "../assets/logo-green-pink-closeup.png";
import { Box, Center, Flex, Image, Spacer } from "@chakra-ui/react";
import { useGetCategoriesQuery } from "../redux/apiSlice";
import BurgerMenu from "../components/burgerMenu";
import { ShoppingCart } from "../components/shoppingCart";

export default function Root() {
  const { data: categories = [], error, isLoading } = useGetCategoriesQuery("");

  return (
    <div style={{ width: "100%" }}>
      <div
        id="header"
        className={"header-height"}
        style={{ width: "100%", position: "sticky", top: 0, zIndex: 1 }}
      >
        <Flex className={"header-height"}>
          <Center className={"headerBox"}>
            <BurgerMenu
              categories={categories}
              isLoading={isLoading}
            ></BurgerMenu>
          </Center>
          <Spacer />
          <Center className={"header-height"}>
            <Link to={"/"}>
              <Image
                src={logo}
                style={{
                  margin: "auto",
                  cursor: "pointer",
                }}
                className={"logo"}
              />
            </Link>
          </Center>
          <Spacer />
          <Center className={"headerBox"}>
            <ShoppingCart />
          </Center>
        </Flex>
      </div>
      <div id="page-wrap">
        <div id="detail">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
