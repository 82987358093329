import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { green500 } from "../assets/tokens";
import { donate } from "../network/endpoints";
import { SocialLinks } from "../components/SocialLinks";

export const DonateNowFn = async () => {
  let url: null | string = null;
  await donate()
    .then((res: { data: any }) => {
      const checkoutSession = res.data.checkout_session;
      url = checkoutSession.url;
    })
    .catch((err: any) => {
      console.log(err);
    });
  if (url) {
    window.location.href = url;
  }
};

const DonatePage = () => {
  return (
    <Stack
      direction="column"
      spacing="60px"
      align="center"
      justify="center"
      padding="20px"
    >
      <Card align="center">
        <CardHeader></CardHeader>
        <CardBody>
          <Text>
            Together, we can continue to support good causes, enjoy artistic
            collaborations, & build a community where we share art.
          </Text>
          <Text>
            We are excited to continue organizing events & initiatives that make
            a positive difference.
          </Text>
        </CardBody>
        <CardFooter>
          <Stack direction="column" spacing={4}>
            <Button
              bgColor={green500}
              color="white"
              size="md"
              onClick={() => DonateNowFn()}
            >
              EasyPay
            </Button>
          </Stack>
        </CardFooter>
      </Card>
      <SocialLinks />
    </Stack>
  );
};

export default DonatePage;
