import React, { useEffect } from "react";
import { Artwork } from "../types";
import { Center, Spinner, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import {
  useGetArtistsQuery,
  useGetArtworksCountQuery,
  useGetArtworksQuery,
} from "../redux/apiSlice";
import ArtworkCard from "../components/artworkCard";
import InfiniteScroll from "../components/infiniteScroll";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getIsFetchingArtworks,
  setIsFetchingArtworks,
} from "../redux/artworksSlice";
import { IMAGE_COUNT } from "../settings";
import _ from "lodash";
import { Search } from "../components/Search";
import { loadFromLocalStorage } from "../localStorage";

const CategoryList = () => {
  const dispatch = useAppDispatch();
  const [skip, setSkip] = React.useState(true);
  const { data: artists = [] } = useGetArtistsQuery("");
  const { data: artworksCount = 0 } = useGetArtworksCountQuery("");
  const [offset, setOffset] = React.useState<string>("0");
  const [artworkIds, setArtworkIds] = React.useState<number[]>([]);
  const [currentArtworkIdBatch, setCurrentArtworkIdBatch] = React.useState<
    number[]
  >([]);
  const [currentArtworks, setCurrentArtworks] = React.useState<Artwork[]>([]);
  const [loadedArtworks, setLoadedArtworks] = React.useState<Artwork[]>([]);
  const isFetchingArtworks: boolean = useAppSelector((state) =>
    getIsFetchingArtworks(state),
  );
  const [searchTerms, setSearchTerms] = React.useState<string[] | null>(null);
  const getArtist = (artistId: number) => {
    return artists.find((artist: any) => artist.id === artistId);
  };

  const {
    data: fetchedArtworks = [],
    error,
    isLoading,
  } = useGetArtworksQuery(
    {
      categoryId: undefined,
      artworkIds: currentArtworkIdBatch,
    },
    { skip },
  );

  const {
    data: taggedArtworks = [],
    error: errorLoadingTaggedArtworks,
    isLoading: isLoadingTaggedArtworks,
  } = useGetArtworksQuery(
    {
      categoryId: undefined,
      tags:
        searchTerms && searchTerms.length > 0 && searchTerms[0].length > 0
          ? searchTerms
          : null,
    },
    { skip },
  );

  useEffect(() => {
    if (artworksCount && artworksCount > 0) {
      // Create array of IDs from 1 to artworksCount
      const ids = _.shuffle(
        Array.from({ length: artworksCount }, (_, i) => i + 1),
      );
      setArtworkIds(ids);
      setCurrentArtworkIdBatch(ids.slice(0, IMAGE_COUNT));
      setSkip(false);
    }
  }, [artworksCount, skip]);

  useEffect(() => {
    if (fetchedArtworks && fetchedArtworks.length > 0) {
      const newArtworks = fetchedArtworks.filter((fetchedArtwork: Artwork) => {
        return !loadedArtworks.find(
          (artwork: Artwork) => artwork.id === fetchedArtwork.id,
        );
      });
      if (newArtworks && newArtworks.length > 0) {
        setCurrentArtworks([...currentArtworks, ...newArtworks]);
        setLoadedArtworks([...loadedArtworks, ...newArtworks]);
        dispatch(setIsFetchingArtworks(false));
      }
    }
  }, [offset, fetchedArtworks]);

  useEffect(() => {
    const offsetSliceStart = parseInt(offset) / IMAGE_COUNT;
    setCurrentArtworkIdBatch(
      artworkIds.slice(
        offsetSliceStart * IMAGE_COUNT,
        (offsetSliceStart + 1) * IMAGE_COUNT,
      ),
    );
  }, [offset]);

  useEffect(() => {
    if (
      searchTerms &&
      !isLoadingTaggedArtworks &&
      taggedArtworks &&
      taggedArtworks.length > 0
    ) {
      const taggedArtworkIds = taggedArtworks.map(
        (artwork: Artwork) => artwork.id,
      );
      const filteredArtworks = loadedArtworks.filter((artwork) =>
        taggedArtworkIds.includes(artwork.id),
      );

      const newArtworks = taggedArtworks.filter(
        (fetchedArtwork: Artwork) =>
          !loadedArtworks.find(
            (artwork: Artwork) => artwork.id === fetchedArtwork.id,
          ),
      );

      setCurrentArtworks([...filteredArtworks, ...newArtworks]);
    } else {
      setCurrentArtworks(loadedArtworks);
    }
  }, [searchTerms, taggedArtworks]);

  const handleSetSearchTerms = (searchTerms: string[]) => {
    if (searchTerms.length === 0 || searchTerms[0].length === 0) {
      setSearchTerms(null);
    } else {
      setSearchTerms(searchTerms);
    }
  };

  return (
    <VStack>
      <Search
        updateSearchTerms={handleSetSearchTerms}
        isSearchingDisabled={isFetchingArtworks}
      />
      {searchTerms &&
        searchTerms.length > 0 &&
        currentArtworks.length === 0 && (
          <Center margin={"20px"}>
            <p>No artworks found for search terms: {searchTerms.join(" ")}</p>
          </Center>
        )}
      <InfiniteScroll
        items={currentArtworks}
        maxItems={artworksCount}
        currentOffset={parseInt(offset)}
        isFetching={isFetchingArtworks}
        setOffset={setOffset}
        preventFetch={!!searchTerms}
      >
        <Wrap id="artwork-list" spacing="60px" justify="center">
          {currentArtworks &&
            !error &&
            !isLoading &&
            currentArtworks
              .filter((artwork) => artwork.artist_id !== 2)
              .map((artwork: Artwork) => (
                <WrapItem key={`artwork-${artwork.id}`}>
                  <ArtworkCard
                    artwork={artwork}
                    artist={getArtist(artwork.artist_id)}
                  ></ArtworkCard>
                </WrapItem>
              ))}
        </Wrap>
        {isFetchingArtworks && (
          <Center margin={"20px"}>
            <Spinner size={"xl"} color={"green.500"} />
          </Center>
        )}
      </InfiniteScroll>
    </VStack>
  );
};

export default CategoryList;
