export const formatNameUrl = (name: string) => {
  return name.toLowerCase().replace(/ /g, "-");
};

export const capitalise = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const buildUrl = (
  base: string,
  params: { [key: string]: any },
): string => {
  const dummyURL = "http://www.test.com";
  const url = new URL(`${dummyURL}${base}`); // add dummy URL to avoid error
  Object.keys(params).forEach((key, index) => {
    if (params[key]) {
      url.searchParams.append(key, params[key]);
    }
  });
  return url.toString().split(dummyURL)[1];
};
