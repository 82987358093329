import React, { useEffect } from "react";
import { useAppDispatch } from "../redux/hooks";
import { setIsFetchingArtworks } from "../redux/artworksSlice";
import { IMAGE_COUNT } from "../settings";

type Props = {
  children?: React.ReactNode;
  items: any[];
  maxItems: number;
  currentOffset: number;
  isFetching: boolean;
  setOffset: (offset: string) => void;
  preventFetch?: boolean;
};

const InfiniteScroll: React.FC<Props> = ({
  children,
  items,
  maxItems,
  currentOffset,
  isFetching,
  setOffset,
  preventFetch,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        window.scrollY + window.innerHeight >= document.body.offsetHeight - 100; // using buffer of 100px to trigger fetch
      const maxOffset = (Math.ceil(maxItems / IMAGE_COUNT) - 1) * IMAGE_COUNT;
      if (bottom && currentOffset < maxOffset) {
        if (!preventFetch && !isFetching) {
          dispatch(setIsFetchingArtworks(true));
          setOffset((currentOffset + IMAGE_COUNT).toString());
        }
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // @ts-ignore
  }, [currentOffset, items, maxItems]);

  return <>{children}</>;
};

export default InfiniteScroll;
