import { headers, network } from "./network";

const baseUrl = process.env.REACT_APP_API_URL;

type CheckoutItem = {
  price: string;
  quantity: number;
};

export const checkout = async (items: CheckoutItem[]) => {
  return await network.post(
    `${baseUrl}/checkout`,
    { line_items: items },
    {
      headers: {
        ...headers,
      },
    },
  );
};

export const donate = async () => {
  return await network.post(
    `${baseUrl}/donate`,
    {},
    {
      headers: {
        ...headers,
      },
    },
  );
};

export const getCheckout = async (sessionId: string) => {
  return await network.get(`${baseUrl}/checkout?session_id=${sessionId}`, {
    headers: {
      ...headers,
    },
  });
};
