import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Artwork } from "../types";
import { RootState } from "./store";

interface ArtworksState {
  artworks: Artwork[];
  fetchedArtworks: Artwork[];
  isFetchingArtworks: boolean;
}

const initialState = {
  artworks: [],
  fetchedArtworks: [],
  isFetchingArtworks: false,
} as ArtworksState;

export const artworksSlice = createSlice({
  // State maintained by always keeping a copy of the last fetched artworks. If a request is made for the same artworks,
  // the state is not updated.
  name: "artworks",
  initialState,
  reducers: {
    updateArtworks(state, action: PayloadAction<Artwork[]>) {
      if (
        !state.fetchedArtworks.length ||
        (action.payload?.[0]?.id &&
          state.fetchedArtworks[0].id !== action.payload[0].id)
      ) {
        const newArtworks = action.payload.filter((fetchedArtwork: Artwork) => {
          return !state.artworks.find(
            (artwork: Artwork) => artwork.id === fetchedArtwork.id,
          );
        });
        if (newArtworks && newArtworks.length > 0) {
          state.fetchedArtworks = newArtworks;
          state.artworks = [...state.artworks, ...newArtworks];
          state.isFetchingArtworks = false;
        }
      }
    },
    clearArtworks(state) {
      state.artworks = [];
    },
    setIsFetchingArtworks(state, action: PayloadAction<boolean>) {
      state.isFetchingArtworks = action.payload;
    },
  },
});

export const { updateArtworks, clearArtworks, setIsFetchingArtworks } =
  artworksSlice.actions;
export const getArtworks = (state: RootState) => state.artworks.artworks;
export const getArtworksCount = (state: RootState) =>
  state.artworks.artworks.length;

export const getIsFetchingArtworks = (state: RootState) =>
  state.artworks.isFetchingArtworks;
