import React from "react";
import { CartItem } from "../types";
import {
  Box,
  Center,
  Flex,
  Image,
  Heading,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Divider,
} from "@chakra-ui/react";
import { decrementItem, incrementItem } from "../redux/shoppingCartSlice";
import { useAppDispatch } from "../redux/hooks";

type Props = {
  item: CartItem;
};

export const CartItemView: React.FC<Props> = ({ item }) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Flex>
        <Center w="70px">
          <Image
            src={item.artwork.image}
            alt={item.artwork.image}
            borderRadius="sm"
            boxSize="64px"
          />
        </Center>
        <Box flex="1">
          <Heading fontSize={"md"} m={"10px"}>
            {item.product.name}
          </Heading>
          <Text m={"10px"}>{item.product.description}</Text>
        </Box>
        <Center w="60px">
          <NumberInput defaultValue={item.quantity} min={0}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper
                onClick={() => dispatch(incrementItem(item.id))}
              />
              <NumberDecrementStepper
                onClick={() => dispatch(decrementItem(item.id))}
              />
            </NumberInputStepper>
          </NumberInput>
        </Center>
        <Center>
          <Text m={"10px"}>${(item.price.unit_amount / 100).toFixed(2)}</Text>
        </Center>
      </Flex>
      <Divider marginY={"10px"} />
    </>
  );
};
